import React, { useState, useEffect } from "react";
import Card from "../../components/Card";
import Item from "../../components/Item";
import Title from "../../components/Title";

export default function One({
  storeData,
  storeDatas,
  filteredDataByRegion,
  filteredDataByDistrict,
  dataSource,
}) {
  const [regionalRanking, setRegionalRanking] = useState();
  const [districtRanking, setDistrictRanking] = useState();
  const [regionRank, setRegionRank] = useState();
  const [distrctRank, setDistrictRank] = useState();
  const [yearToYearChange, setYearToYearChange] = useState();

  useEffect(() => {
    //Store view stats
    let regionRank = filteredDataByRegion
      .sort((a, b) => b[14] - a[14])
      .findIndex((data) => data[5] === storeData[5]);
    let districtRank = filteredDataByDistrict
      .sort((a, b) => b[14] - a[14])
      .findIndex((data) => data[5] === storeData[5]);
    setRegionalRanking(regionRank + 1);
    setDistrictRanking(districtRank + 1);
    regionRank = filteredDataByRegion
      .sort((a, b) => b[8] - a[8])
      .findIndex((data) => data[5] === storeData[5]);
    districtRank = filteredDataByDistrict
      .sort((a, b) => b[8] - a[8])
      .findIndex((data) => data[5] === storeData[5]);
    setRegionRank(regionRank + 1);
    setDistrictRank(districtRank + 1);

    //Both store and district view stats
    const yearChange =
      ((storeData[14] - storeData[22]) / Math.abs(storeData[22])) * 100;
    setYearToYearChange(yearChange.toFixed(2));
  }, [storeDatas, storeData, filteredDataByRegion, filteredDataByDistrict]);

  return (
    <div className="flex flex-col w-full">
      <div className="grid grid-cols-2 gap-4 mb-12 mx-32">
        <Card title="RESIDENTIAL TOP-LINE SALES">
          <div className="flex flex-col">
            <Item>
              <Title variant="h2">YEAR OVER YEAR CHANGE</Title>
              <div className="flex items-center justify-center gap-1">
                <p
                  className={`text-[50px] ${
                    yearToYearChange > 0 ? "text-green-700" : "text-[#D71920]"
                  } `}
                >
                  {yearToYearChange === "Infinity"
                    ? "N/A"
                    : `${Math.abs(yearToYearChange)}%`}
                </p>
              </div>
            </Item>
            <Item className="py-6 border-t border-[#D9D9D9]">
              <Title variant="h2">THIS YEAR'S SALES</Title>
              <p className="text-[50px]">
                {new Intl.NumberFormat("us-US", {
                  style: "currency",
                  currency: "USD",
                }).format(storeData[14])}
              </p>
            </Item>
            {dataSource === "store" && (
              <div className="flex justify-center px-10 gap-8 py-3 border-t border-[#D9D9D9]">
                <Item className="py-8">
                  <Title variant="h2">REGIONAL RANKING</Title>
                  <p className="text-4xl">
                    {regionalRanking} of {filteredDataByRegion?.length}
                  </p>
                </Item>
                <div className="w-0.5 bg-gray-400" />
                <Item className="py-8">
                  <Title variant="h2">DISTRICT RANKING</Title>
                  <p className="text-4xl">
                    {districtRanking} of {filteredDataByDistrict?.length}
                  </p>
                </Item>
              </div>
            )}
            {dataSource === "district" && (
              <Item className="py-6 border-t border-[#D9D9D9]">
                <Title variant="h2">Region RANKING</Title>
                <p className="text-[50px]">
                  {regionalRanking} of {filteredDataByRegion?.length}
                </p>
              </Item>
            )}
          </div>
        </Card>

        <Card title="RESIDENTIAL ACCESSORY ATTACHMENT RATE">
          <div className="flex flex-col">
            <Item>
              <Title variant="h2">{dataSource} TOTAL</Title>
              <div className="flex items-center justify-center gap-1">
                <p
                  className={`text-[50px] ${
                    storeData[8] > storeData[44]
                      ? "text-green-700"
                      : "text-[#D71920]"
                  } `}
                >
                  {(storeData[8] * 100).toFixed(2)} %
                </p>
              </div>
            </Item>
            <div>
              {dataSource === "store" && (
                <>
                  <div className="flex justify-center px-10 gap-8 border-t border-[#D9D9D9] py-3">
                    <Item className="py-8">
                      <Title variant="h2">Region Attachment</Title>
                      <p className="text-4xl">
                        {(storeData[44] * 100).toFixed(2)}%
                      </p>
                    </Item>
                    <div className="w-0.5 bg-gray-400" />
                    <Item className="py-8">
                      <Title variant="h2">REGION RANK</Title>
                      <p className="text-4xl">
                        {regionRank} of {filteredDataByRegion?.length}
                      </p>
                    </Item>
                  </div>

                  <div className="flex justify-center px-10 gap-8 border-t border-[#D9D9D9] py-3">
                    <Item className="py-8">
                      <Title variant="h2">District Attachment</Title>
                      <p className="text-4xl">
                        {(storeData[50] * 100).toFixed(2)}%
                      </p>
                    </Item>
                    <div className="w-0.5 bg-gray-400" />
                    <Item className="py-8">
                      <Title variant="h2">DISTRICT RANK</Title>
                      <p className="text-4xl">
                        {distrctRank} of {filteredDataByDistrict?.length}
                      </p>
                    </Item>
                  </div>
                </>
              )}
              {dataSource === "district" && (
                <>
                  <div className="flex justify-center px-10 gap-8 border-t border-b border-[#D9D9D9]">
                    <Item className="py-6">
                      <Title variant="h2">Region Attachment</Title>
                      <div className="flex items-center justify-center gap-1">
                        <p className="text-[50px]">
                          {(storeData[44] * 100).toFixed(2)}%
                        </p>
                      </div>
                    </Item>
                  </div>
                  <div className="flex justify-center px-10 gap-8 border-t border-[#D9D9D9]">
                    <Item className="py-6">
                      <Title variant="h2">Region RANK</Title>
                      <div className="flex items-center justify-center gap-1">
                        <p className="text-[50px]">
                          {regionRank} of {filteredDataByRegion?.length}
                        </p>
                      </div>
                    </Item>
                  </div>
                </>
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
