const componentsData = [
  {
    id: 1,
    name: "Leak Barrier",
    time: 0,
    startTime: 0,
    endTime: 14,
    ticketAmount: 160,
    isComplete: false,
  },
  {
    id: 2,
    name: "Deck Protection",
    time: 15,
    startTime: 15,
    endTime: 28,
    ticketAmount: 297,
    isComplete: false,
  },
  {
    id: 3,
    name: "Starter Strip Shingles",
    time: 32,
    startTime: 32,
    endTime: 40,
    ticketAmount: 145,
    isComplete: false,
  },
  {
    id: 4,
    name: "Shingles",
    time: 41,
    startTime: 41,
    endTime: 50,
    ticketAmount: 3118,
    isComplete: false,
  },
  {
    id: 5,
    name: "Ventilation",
    time: 51,
    startTime: 51,
    endTime: 57,
    ticketAmount: 230,
    isComplete: false,
  },
  {
    id: 6,
    name: "Hip and Ridge Cap Shingles",
    time: 58,
    startTime: 58,
    endTime: 64,
    ticketAmount: 168,
    isComplete: false,
  },
];

export default componentsData;
