import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Online } from "react-detect-offline";
import "./index.css";
// const { ipcRenderer } = window.require("electron");

function App() {
  // const handleExit = () => {
  //   // ipcRenderer.send("close-me");
  // };
  const [link] = useState(
    "https://phpstack-972724-3832411.cloudwaysapps.com/uplink/uploads/"
  );

  const handleExit = () => {
    //   // ipcRenderer.send("close-me");
  };

  const uploadEmail = () => {
    const emails = JSON.parse(localStorage.getItem("forms"));
    gaf_submit_data(emails);
  };

  async function gaf_submit_data(payload) {
    var uplinkUrl = "https://phpstack-972724-3832411.cloudwaysapps.com/uplink/";

    let formData = new FormData();

    formData.append("api", "1.0");
    formData.append("op", "store_data");
    const date = Date.now();
    // setLink(
    //   `http://phpstack-264451-3122782.cloudwaysapps.com/uplink/uploads/gaf_${date}.0.json`
    // );
    formData.append("timestamp", date);
    formData.append("records", JSON.stringify(payload, null, 2));

    window.response = await fetch(uplinkUrl, {
      method: "POST",
      api: "1.0",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => gaf_submitted_data(data));
  }

  function gaf_submitted_data(data) {
    if (data === undefined) {
      alert("ERROR: I was unable to upload the data. Try again later.");
      return;
    }
    if (data.success) {
      console.log(data);
      alert("All records have been remotely stored successfully.");
      return;
    } else {
      console.log(data);
      alert("Failed. " + data.message);
      return;
    }
  }

  return (
    <div className="App bg-red-500 flex flex-col gap-9 h-screen justify-center items-center relative p-40 w-full">
      <div className="text-white text-8xl">Menu</div>
      <div className="flex gap-6">
        <Link
          to="/kiosk1"
          className="border-4 hover:bg-slate-900 border-white w-[360px] h-[400px] flex flex-col justify-center items-center"
        >
          <img src="png/roofingSystem.png" alt="roofing system" />
          <p className="text-white text-[35px] text-center">
            Build a Roofing System
          </p>
        </Link>
        <Link
          to="/kiosk2"
          className="border-4 hover:bg-slate-900 border-white w-[360px] h-[400px] flex flex-col justify-center items-center"
        >
          <img src="png/addUp.png" alt="roofing system" />
          <p className="text-white text-[35px] text-center">System Adds up</p>
        </Link>
        <Link
          to="/kiosk3"
          className="border-4 hover:bg-slate-900 border-white w-[360px] h-[400px] flex flex-col justify-center items-center"
        >
          <img src="png/attachment.png" alt="roofing system" />
          <p className="text-white text-[35px] text-center">Attachment Rate</p>
        </Link>
      </div>
      <Online>
        <div className="flex gap-8">
          <button
            onClick={uploadEmail}
            className="border-4 text-white text-lg font-semibold hover:bg-slate-900 border-white px-6 py-3"
          >
            Upload Emails
          </button>
          <button
            onClick={() => window.open(link)}
            className="border-4 text-white text-lg font-semibold hover:bg-slate-900 border-white px-6 py-3"
          >
            View Submissions
          </button>
        </div>
      </Online>

      <button
        className="absolute left-0 bottom-0 w-14 h-14"
        onDoubleClick={handleExit}
        id="closeApp"
      />
    </div>
  );
}

export default App;
