import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import readXlsxFile from "read-excel-file";
import Header from "../../components/Header";
import One from "./One";
import Two from "./Two";
import Three from "./Three";
import JSONKey from "../../key.json";
import counterJSON from "../../counter.json";

const importKey = async (keyData) => {
  const key = await window.crypto.subtle.importKey('jwk', keyData, { "name": "AES-CTR" }, true, ["encrypt", "decrypt"]);
  return key;
}

const decryptMessage = (publicKey, message) => {
  const plainMessage = Uint8Array.from(atob(message), c => c.charCodeAt(0));
  let counter = Uint8Array.from(Object.values(counterJSON));
  return crypto.subtle.decrypt(
    {
      name: "AES-CTR",
      counter,
      length: 64,
    },
    publicKey,
    plainMessage
  )
}

export default function Location() {
  let { locationId } = useParams();
  let { dataSource } = useParams();
  let { pageState } = useParams();
  const [ key, setKey ] = useState(null);
  const [ decryptedLocationId, setDecryptedLocationId ] = useState(null);
  const originalLocationId = locationId;
  useEffect(() => {
    if(process.env.REACT_APP_MODE==="shareable") {
      importKey(JSONKey).then(key => {
        setKey(key);
      });
    }
  }, []);
  useEffect(() => {
    if(process.env.REACT_APP_MODE==="shareable" && key) {
      const decrypt = async () => {
        decryptMessage(key, locationId).then(decrypted => {
          let dec = new TextDecoder("utf-8");
          let decoded = dec.decode(decrypted);
          decoded = Number(decoded.replaceAll("a", "").substring(3)) / 2;
          if(!!decoded) {
            setDecryptedLocationId(decoded);
          }
          setIsDecrypted(true);
        });

      }
      decrypt();
    }
  }, [key]);
  if(process.env.REACT_APP_MODE==="shareable") {
    if(decryptedLocationId) {
      locationId = decryptedLocationId;
    }
  }
  let spreadsheet = "";
  //States
  const [page, setPage] = useState(0);
  const [storeData, setStoreData] = useState();
  const [storeDatas, setStoreDatas] = useState();
  const [isDecrypted, setIsDecrypted ] = useState(false);
  const [filteredDataByRegion, setFilteredDataByRegion] = useState();
  const [filteredDataByDistrict, setFilteredDataByDistrict] = useState();
  const [incrementState, setIncrementState] = useState("default");
  //Roof data
  const [barrier, setBarrier] = useState();
  const [protection, setProtection] = useState();
  const [starter, setStarter] = useState();
  const [attic, setAttic] = useState();
  const [shingles, setShingles] = useState();

  //Are we pulling the store data or district/region data?
  if (dataSource === "store") {
    spreadsheet = "data_lowes_2023.xlsx";
  } else {
    spreadsheet = "data_lowes_district_2023.xlsx";
  }

  //Get display values for roof product cards
  const roofCalculations = useCallback(() => {
    //Combine shared and unique product values into one object
    const createStateObject = (commonProperties, specificProperties) => {
      return {
        ...commonProperties,
        ...specificProperties,
      };
    };

    //Calculate product values
    const productMath = (product, mainCell) => {
      //Shared values
      let regionRankProduct = filteredDataByRegion
        .sort((a, b) => b[mainCell] - a[mainCell])
        .findIndex((data) => data[5] === storeData[5]);
      let districtRankProduct = filteredDataByDistrict
        .sort((a, b) => b[mainCell] - a[mainCell])
        .findIndex((data) => data[5] === storeData[5]);

      let commonProperties = {
        totalStore: (storeData[mainCell] * 100).toFixed(2),
        regionRank: regionRankProduct + 1,
        totalRegion: filteredDataByRegion.length,
        districtRank: districtRankProduct + 1,
        totalDistrict: filteredDataByDistrict.length,
      };

      //Product-specific values
      let specificProperties = {};

      if (product === "barrier") {
        specificProperties = {
          incrementalSales: storeData[33],
          pointFiveIncrement: storeData[38],
          onePercentIncrement: storeData[38] * 2,
          itemTicket: 160,
          regionAttachment: storeData[47],
          districtAttachment: storeData[53],
          isUp: storeData[mainCell] > storeData[47],
        };
        setBarrier((prev) =>
          createStateObject(commonProperties, specificProperties)
        );
      } else if (product === "protection") {
        specificProperties = {
          incrementalSales: storeData[34],
          pointFiveIncrement: storeData[39],
          onePercentIncrement: storeData[39] * 2,
          itemTicket: 297,
          regionAttachment: storeData[48],
          districtAttachment: storeData[54],
          isUp: storeData[mainCell] > storeData[48],
        };
        setProtection((prev) =>
          createStateObject(commonProperties, specificProperties)
        );
      } else if (product === "starter") {
        specificProperties = {
          incrementalSales: storeData[35],
          pointFiveIncrement: storeData[40],
          onePercentIncrement: storeData[40] * 2,
          itemTicket: 148,
          regionAttachment: storeData[49],
          districtAttachment: storeData[55],
          isUp: storeData[mainCell] > storeData[49],
        };
        setStarter((prev) =>
          createStateObject(commonProperties, specificProperties)
        );
      } else if (product === "attic") {
        specificProperties = {
          incrementalSales: storeData[31],
          pointFiveIncrement: storeData[36],
          onePercentIncrement: storeData[36] * 2,
          itemTicket: 226,
          regionAttachment: storeData[45],
          districtAttachment: storeData[51],
          isUp: storeData[mainCell] > storeData[45],
        };
        setAttic((prev) =>
          createStateObject(commonProperties, specificProperties)
        );
      } else if (product === "shingles") {
        specificProperties = {
          incrementalSales: storeData[32],
          pointFiveIncrement: storeData[37],
          onePercentIncrement: storeData[37] * 2,
          itemTicket: 171,
          regionAttachment: storeData[46],
          districtAttachment: storeData[52],
          isUp: storeData[mainCell] > storeData[46],
        };
        setShingles((prev) =>
          createStateObject(commonProperties, specificProperties)
        );
      }
    };

    // 1 LEAK BARRIER - Column L in xlsx
    productMath("barrier", 11);
    // 2 DECK PROTECTION - Column M in xlsx
    productMath("protection", 12);
    // 3 STARTER STRIP SHINGLES - Column N in xlsx
    productMath("starter", 13);
    // 4 ATTIC (COBRA) VENTILATION - Column J in xlsx
    productMath("attic", 9);
    // 5 HIP AND RIDGE CAP SHINGLES - Column K in xlsx
    productMath("shingles", 10);
  }, [storeData, filteredDataByDistrict, filteredDataByRegion]);

  //Get data from the spreadsheet
  useEffect(() => {
    const getData = async () => {
      try {
        fetch(spreadsheet) //remote data is at https://phpstack-264451-3122782.cloudwaysapps.com/datav2.xlsx
          .then((response) => response.blob())
          .then((blob) => readXlsxFile(blob))
          .then((rows) => {
            const filteredRow = rows.filter((row) => {
              return row[5] === parseInt(locationId);
            });

            const filteredDataByRegion = rows?.filter(
              (data) => data[1] === filteredRow[0][1]
            );
            setFilteredDataByRegion(filteredDataByRegion);

            const filteredDataByDistrict = rows?.filter(
              (data) => data[3] === filteredRow[0][3]
            );
            setFilteredDataByDistrict(filteredDataByDistrict);

            setStoreDatas(rows);
            setStoreData(filteredRow[0]);
            // `rows` is an array of rows
            // each row being an array of cells.

            console.log(rows);
          });
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    getData();
  }, [locationId, spreadsheet]);

  //Make sure store/region variables have values attached to them before calculating data-points.
  useEffect(() => {
    if (filteredDataByRegion && storeData && storeDatas) {
      //Generate values for roofing system features
      roofCalculations();
    }
    //Set view based on passed int parameter in url
    setPage(parseInt(pageState));
  }, [
    filteredDataByRegion,
    storeData,
    storeDatas,
    pageState,
    roofCalculations,
  ]);

  if(process.env.REACT_APP_MODE==="shareable" && !isDecrypted) {
    return <></>;
  }
  if(process.env.REACT_APP_MODE==="shareable" && !decryptedLocationId && !!storeData) {
    setStoreData(null);
  }

  console.log(decryptedLocationId);

  return (
    <div className="flex flex-col h-screen bg-white w-full">
      <div className="disclaimer">
      View this page on a computer for the best experience!
      </div>
      {/* Header for the data table views (not shown in roof view) */}
      {page !== 3 && (
        <header className="mb-10">
          <Header title={`${dataSource} Attachment Rate Sales Data`} />
          <div className="store-header flex justify-center items-center bg-gray-100 py-2 text-xl font-medium relative mb-2">
            <div className="flex items-center gap-6">
              <img src="svg/lowes_logo.svg" alt="Lowes Logo" width={100} />
              <div>
                {storeData && (
                  <>
                    <p className="font-bold text-xl text-[#1C488B] capitalize">
                      {`${dataSource} ${locationId}`}
                    </p>
                    <p className="text-[#1C488B] text-lg">
                      {dataSource === "store" ? storeData[6] : storeData[4]}
                    </p>
                  </>
                )}
              </div>
            </div>
            {page === 2 && (
              <div
                className="flex font-semibold gap-2 text-[#D71920] justify-center items-center cursor-pointer absolute left-4"
                onClick={() => setPage(1)}
              >
                <img src="png/arrow_prev.png" alt="prev" className="h-[50px]" />
                <p className="w-20 text-base font-semibold text-left capitalize  leading-none">
                  Back to {dataSource} Overview
                </p>
              </div>
            )}
            {page === 1 && (
              <div
                className="flex font-semibold gap-2 text-[#D71920] justify-center items-center cursor-pointer absolute right-4"
                onClick={() => setPage(2)}
              >
                <p className="w-20 text-base font-semibold text-right capitalize leading-none">
                  View {dataSource} Details
                </p>
                <img src="png/arrow_next.png" alt="next" className="h-[50px]" />
              </div>
            )}
          </div>
          {page === 2 && (
            <div className="flex justify-center gap-8 items-center bg-gray-100 py-2 text-base capitalize">
              <p>
                Total {dataSource} shingle sales:{" "}
                <span className="font-bold">
                  ${storeData[21].toLocaleString()}
                </span>
              </p>
              <p>
                Total {dataSource} Accessory sales:{" "}
                <span className="font-bold">
                  ${storeData[15].toLocaleString()}
                </span>
              </p>
            </div>
          )}
        </header>
      )}
      {/* Error screen for invalid location id */}
      {!storeData && (
        <div className="flex flex-col items-center justify-center h-full font-bold text-3xl text-center animate fade delay-4 capitalize">
          No Data Available for this {dataSource} Number <br /> ASK ABOUT
          SPECIAL ORDER
          <div className="flex justify-center items-center gap-6 pt-4 w-full">
            { process.env.REACT_APP_MODE!=="shareable" && <>
            <Link
              className="py-3 px-9 bg-[#D71920] text-xl max-w-sm mt-4 text-white font-bold cursor-pointer w-full text-center animate glow delay-5 capitalize"
              to={`/kiosk3`}
            >
              Return to {dataSource} Select
            </Link>
            <Link
              to={`/partner/${dataSource}/${locationId}`}
              className="py-3 px-9 bg-[#D71920] text-xl max-w-sm mt-4 text-white font-bold cursor-pointer w-full text-center animate glow delay-5 capitalize"
            >
              Connect Me With An RSR
            </Link>
            </> }
          </div>
        </div>
      )}
      {/* If data exists, route to the correct view component */}
      {storeData && (
        <>
          {page === 1 ? (
            <One
              dataSource={dataSource}
              storeData={storeData}
              storeDatas={storeDatas}
              filteredDataByDistrict={filteredDataByDistrict}
              filteredDataByRegion={filteredDataByRegion}
            />
          ) : page === 2 ? (
            <Two
              dataSource={dataSource}
              barrier={barrier}
              protection={protection}
              starter={starter}
              shingles={shingles}
              attic={attic}
              incrementState={incrementState}
              filteredDataByRegion={filteredDataByRegion}
            />
          ) : (
            <Three
              dataSource={dataSource}
              locationId={locationId}
              originalLocationId={originalLocationId}
              storeDatas={storeDatas}
              storeData={storeData}
              barrier={barrier}
              protection={protection}
              starter={starter}
              shingles={shingles}
              attic={attic}
            />
          )}
        </>
      )}
      {/* Footer for the data table views (not shown in roof view) */}
      {page !== 3 && (
        <div
          className={`flex items-center border-t-2 border-gray-300 pt-4 px-16 ${
            page === 2 ? "justify-between" : "justify-center"
          }`}
        >
          <div className="flex gap-6 ">
            { process.env.REACT_APP_MODE!=="shareable" && <>
            <Link
              to={`/kiosk3`}
              className="roof-view-connect text-[#D71920] bg-stone-50 py-2 px-5 text-lg rounded-full uppercase border-[#D71920] border-solid border-2 text-center min-w-[150px] uppercase"
            >
              {dataSource} ID
            </Link>
            <Link
              to={`/partner/${dataSource}/${locationId}`}
              className="roof-view-connect text-[#D71920] bg-stone-50 py-2 px-5 text-lg rounded-full border-[#D71920] border-solid border-2 text-center min-w-[150px] uppercase"
            >
              Connect Me With An RSR
            </Link>
            </>
            }
            {
            process.env.REACT_APP_MODE==="shareable" && <>
            <Link
              to={`/location/${dataSource}/${originalLocationId}/3`}
              className="roof-view-connect text-[#D71920] bg-stone-50 py-2 px-5 text-lg rounded-full uppercase border-[#D71920] border-solid border-2 text-center capitalize min-w-[150px] uppercase"
            >
              Go to roof view
            </Link>
            </>
            }
          </div>
          {page === 2 && (
            <div className="flex items-center justify-center gap-2">
              <button
                className={`roof-view-connect py-2 px-5 text-lg rounded-full uppercase border-[#D71920] border-solid border-2 text-center min-w-[150px] uppercase ${
                  incrementState === "pointFiveIncrease"
                    ? "bg-[#D71920] text-white"
                    : "bg-stone-50 text-[#D71920]"
                }`}
                onClick={() => setIncrementState("pointFiveIncrease")}
              >
                .5%
              </button>
              <button
                className={`roof-view-connect py-2 px-5 text-lg rounded-full uppercase border-[#D71920] border-solid border-2 text-center min-w-[150px] uppercase ${
                  incrementState === "default"
                    ? "bg-[#D71920] text-white"
                    : "bg-stone-50 text-[#D71920]"
                }`}
                onClick={() => setIncrementState("default")}
              >
                Dollars Missed
              </button>
              <button
                className={`roof-view-connect py-2 px-5 text-lg rounded-full uppercase border-[#D71920] border-solid border-2 text-center min-w-[150px] uppercase ${
                  incrementState === "onePercentIncrease"
                    ? "bg-[#D71920] text-white"
                    : "bg-stone-50 text-[#D71920]"
                }`}
                onClick={() => setIncrementState("onePercentIncrease")}
              >
                1%
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
