import { useEffect, useState } from "react";
import GAFCard from "../../components/GAFCard";
import IncrementalCell from "../../components/IncrementalCell";

export default function Two({
  dataSource,
  storeDatas,
  barrier,
  protection,
  starter,
  attic,
  shingles,
  incrementState,
  filteredDataByRegion,
}) {
  const [missedSales, setMissedSales] = useState(0);
  const [incrementText, setIncrementText] = useState(
    "Total Sales Dollars Missed:"
  );

  useEffect(() => {
    let rawMissedSales = 0;

    if (incrementState === "pointFiveIncrease") {
      rawMissedSales =
        barrier.pointFiveIncrement +
        protection.pointFiveIncrement +
        starter.pointFiveIncrement +
        attic.pointFiveIncrement +
        shingles.pointFiveIncrement;
      setIncrementText("Incremental Opportunity at 0.5%:");
    } else if (incrementState === "onePercentIncrease") {
      rawMissedSales =
        barrier.onePercentIncrement +
        protection.onePercentIncrement +
        starter.onePercentIncrement +
        attic.onePercentIncrement +
        shingles.onePercentIncrement;
      setIncrementText("Incremental Opportunity at 1%:");
    } else {
      rawMissedSales =
        barrier.incrementalSales +
        protection.incrementalSales +
        starter.incrementalSales +
        attic.incrementalSales +
        shingles.incrementalSales;
      setIncrementText("Total Sales Dollars Missed:");
    }

    const finalMissedSales = new Intl.NumberFormat("us-US", {
      style: "currency",
      currency: "USD",
    })
      .format(Math.round(rawMissedSales))
      .slice(0, -3);

    setMissedSales(finalMissedSales);
  }, [incrementState]);

  return (
    <div className="flex flex-col items-center justify-center w-full px-16">
      <div className="w-full grid grid-cols-5 gap-3 mb-4">
        <GAFCard
          title="LEAK BARRIER"
          {...barrier}
          id="1"
          dataSource={dataSource}
          storeDatas={storeDatas}
          filteredDataByRegion={filteredDataByRegion}
        />
        <GAFCard
          title="DECK PROTECTION"
          {...protection}
          id="2"
          dataSource={dataSource}
          storeDatas={storeDatas}
          filteredDataByRegion={filteredDataByRegion}
        />
        <GAFCard
          title="STARTER STRIP SHINGLES"
          {...starter}
          id="3"
          dataSource={dataSource}
          storeDatas={storeDatas}
          filteredDataByRegion={filteredDataByRegion}
        />
        <GAFCard
          title="ATTIC VENTILATION"
          {...attic}
          id="5"
          dataSource={dataSource}
          storeDatas={storeDatas}
          filteredDataByRegion={filteredDataByRegion}
        />
        <GAFCard
          title="HIP AND RIDGE CAP SHINGLES"
          {...shingles}
          id="6"
          dataSource={dataSource}
          storeDatas={storeDatas}
          filteredDataByRegion={filteredDataByRegion}
        />
      </div>

      <div className="incremental-data w-full">
        <div className="incremental-data-divider flex text-xs gap-4 items-center mb-3">
          <span className="w-full h-0.5 bg-[#707070]"></span>
          <div className="w-2/4 text-center">
            INCREMENTAL SALES DOLLARS (MISSED $)
          </div>
          <span className="w-full h-0.5 bg-[#707070]"></span>
        </div>
        {incrementState === "default" && (
          <div className="w-full grid grid-cols-5 gap-3 mb-4">
            <IncrementalCell {...barrier} incrementState={incrementState} />
            <IncrementalCell {...protection} incrementState={incrementState} />
            <IncrementalCell {...starter} incrementState={incrementState} />
            <IncrementalCell {...attic} incrementState={incrementState} />
            <IncrementalCell {...shingles} incrementState={incrementState} />
          </div>
        )}
        <div className="flex items-center justify-center w-full text-xl bg-[#D71920] text-stone-50 text-center py-6 gap-16 mb-4">
          {incrementText}
          <span className="text-[42px]">{missedSales}</span>
        </div>
      </div>
    </div>
  );
}
